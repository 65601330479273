import React from 'react';
import styled from 'styled-components';
import {Layout} from "../containers/Layout";
import {PrivacyPolicy} from "../components/PrivacyPolicy";
import {PageProps} from "gatsby";
import {AppBarHeight, CenterSectionCSS, Media} from "../constants";


export default function Privacy(props: PageProps) {
    return <Layout {...props} meta={{title: 'Privacy Policy'}}>
        <Content>
            <PrivacyPolicy/>
        </Content>
    </Layout>;
}

const Content = styled.div`
   ${CenterSectionCSS};
  padding-top: ${AppBarHeight.desktop}rem;           
  @media (${Media.tablet}) {
     padding-top: 0;
  }
  @media (${Media.desktop}) {    
     padding-top: ${AppBarHeight.desktop + 2}rem;        
  } 
  @media (${Media.desktop}) {    
      min-height: 66vh;
  }   
`;

